import Vue from "vue";
import VueI18n from "vue-i18n";
import csv from '@/assets/strings.csv'
import parse from "csv-parse/lib/sync";

Vue.use(VueI18n);

const numberFormats = {
  'en': {
    currency: {
      currency: 'USD',
      currencySign : '',
      style: 'currency', 
    },
    currencyNoCents: {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0, // set fraction digits to 0 to remove cents
      maximumFractionDigits: 0
    }
  }
}

export const i18n = new VueI18n({
  silentFallbackWarn: true,
  locale: navigator.language, // set locale
  fallbackLocale: "en",
  numberFormats,
  messages: []
});

let initialized = false;
export async function initialize() {
  if (initialized) return;
  
  const records = parse(csv, { delimiter: ";" });

  const langs = [];
  for (let i = 1; i < records[0].length; i++) {
    langs.push(records[0][i]);
  }
  
  for (let i = 0; i < langs.length; i++) {
    const langName = langs[i];
    const messages = {};
    for (let j = 1; j < records.length; j++) {
      const key = records[j][0];
      const value = records[j][i + 1];
      messages[key] = value;
    }
    i18n.setLocaleMessage(langName, messages);
  }
  initialized = true;
}
