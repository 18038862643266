import axios from "axios";
const state = {
  loading: false,
  adding: false,
  addingExclusion: false,
  deletingExclusions: false,
  deleting: false,
  exclusionLists: []
};

const getters = {
  loadingExclusionLists: state => state.loading,
  addingExclusionList: state => state.adding,
  deletingExclusionList: state => state.deleting,
  exclusionLists: state => state.exclusionLists,
  addingExclusion: state => state.addingExclusion,
  deletingExclusions: state => state.deletingExclusions,
};

const actions = {
  async fetchExclusionLists({ state }, params) {
    if (params && !params.refetch && state.exclusionLists) return;

    state.loading = true;
    try {
      const response = await axios.get("exclusions");
      state.exclusionLists = response.data;
    } finally {
      state.loading = false;
    }
  },
  async addExclusionList({ state }, list) {
    state.adding = true;

    try {
      await axios.post("exclusions", list);
    } finally {
      state.adding = false;
    }
  },
  async addDanDomainManufacturersExclusionList({ state }, list) {
    state.adding = true;

    try {
      await axios.post("exclusions/dandomain/manufacturers", list);
    } finally {
      state.adding = false;
    }
  },  
  async addDDHostedShopBrandsExclusionList({ state }, list) {
    state.adding = true;

    try {
      await axios.post("exclusions/DDHostedShop/brands", list);
    } finally {
      state.adding = false;
    }
  },
  async deleteExclusionList({ state }, list) {
    state.deleting = true;

    const index = state.exclusionLists.indexOf(list);
    state.exclusionLists.splice(index, 1);

    try {
      await axios.delete(`exclusions/${list.id}`);
    } catch (e) {
      state.exclusionLists.splice(index, 0, list);
    } finally {
      state.deleting = false;
    }
  },
  async addExclusion({ state }, { list, newExclusion }) {
    state.addingExclusion = true;

    try {
      const response = await axios.post(`exclusions/${list.id}/exclusions`, { value: newExclusion });
      var exclusion = response.data;
      list.exclusions.push(exclusion);
    } finally {
      state.addingExclusion = false;
    }
  },
  async deleteExclusions({ state }, { list, exclusionsToDelete }) {
    state.deletingExclusions = true;
    state.deletingReplacements = true;
    exclusionsToDelete.forEach(exclusions => {
      const index = list.exclusions.indexOf(exclusions);
      list.exclusions.splice(index, 1);
    });

    try {
      await axios.post(`exclusions/${list.id}/exclusions/bulkdelete`, { ids: exclusionsToDelete.map(r => r.id) });
    }
    catch (e) {
      list.exclusions.push(...exclusionsToDelete);
      throw e;
    }
    finally {
      state.deletingExclusions = false;
    }
  }
};

const mutations = {
  clearExclusionLists(state) {
    state.exclusionLists = [];
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
