import axios from "axios";
import { i18n } from "./vue-i18n";
import auth from "@/auth";
import Vue from 'vue'

axios.defaults.baseURL = process.env.VUE_APP_APIURL;

axios.interceptors.request.use(request => {
  if (!auth.isAuthorized()) return request;

  request.headers.authorization = "Bearer " + auth.getToken();
  return request;
});

axios.interceptors.response.use(
  response => {
    return response;
  },
  err => {
    console.log(err);
    console.log(err.response);
    if (err.response && err.response.status < 500) {
      throw err.response.data;
    }
    Vue.notify({
      group: 'errors',
      title: i18n.t("Generic.ErrorTitle"),
      text: i18n.t("Generic.Error"),
      type: 'error'
    })

    throw err;
  }
);
