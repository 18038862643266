import axios from "axios";

const state = {
    integrations: [],
    fetching: false
};

const getters = {
    integrations: state => state.integrations,    
    fetching: state => state.fetching
};

const actions = {
    async fetch({ state }) {
        state.fetching = true;
        try {
            const response = await axios.get("integrations");
            state.integrations = response.data.values;
        } finally {
            state.fetching = false;
        }
    }
};

const mutations = {};

export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true
};