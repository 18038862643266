import axios from "axios";
const state = {
    loading: false,
    adding: false,
    addingReplacement: false,
    deletingReplacements: false,
    deleting: false,
    replacementLists: []
};

const getters = {
    loadingReplacementLists: state => state.loading,
    addingReplacementList: state => state.adding,
    deletingReplacementList: state => state.deleting,
    replacementLists: state => state.replacementLists,
    addingReplacement: state => state.addingReplacement,
    deletingReplacements: state => state.deletingReplacements,
};

const actions = {
    async fetchReplacementLists({ state }, params) {
        if (params && !params.refetch && state.replacementLists) return;

        state.loading = true;
        try {
            const response = await axios.get("FindAndReplacements");
            state.replacementLists = response.data;
        } finally {
            state.loading = false;
        }
    },
    async addReplacementList({ state }, list) {
        state.adding = true;

        try {
            await axios.post("FindAndReplacements", list);
        } finally {
            state.adding = false;
        }
    },
    async deleteReplacementList({ state }, list) {
        state.deleting = true;

        const index = state.replacementLists.indexOf(list);
        state.replacementLists.splice(index, 1);

        try {
            await axios.delete(`FindAndReplacements/${list.id}`);
        } catch (e) {
            state.replacementLists.splice(index, 0, list);
        }
        finally {
            state.deleting = false;
        }
    },
    async addReplacement({ state }, { list, newReplacement }) {
        state.addingReplacement = true;

        try {
            const response = await axios.post(`FindAndReplacements/${list.id}/replacements`, newReplacement);
            var replacement = response.data;
            list.replacements.push(replacement);
        } finally {
            state.addingReplacement = false;
        }
    },
    async deleteReplacements({ state }, { list, replacementsToDelete }) {

        state.deletingReplacements = true;
        replacementsToDelete.forEach(replacement => {
          const index = list.replacements.indexOf(replacement);
          list.replacements.splice(index, 1);
        });

        try {
            await axios.post(`FindAndReplacements/${list.id}/replacements/bulkdelete`, { ids: replacementsToDelete.map(r => r.id) });
        }
        catch (e) {
            list.replacements.push(...replacementsToDelete);
            throw e;
          }
        finally {
            state.deletingReplacements = false;
        }
    }
};

const mutations = {};

export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true
};
