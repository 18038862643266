import axios from "axios";

const state = {
    verifying: false,
    verified: false,
    adding: false,
    updating: false,
    deleting: false,
    newIntegrationId: ""
};

const getters = {
    verifying: state => state.verifying,
    adding: state => state.adding,
    updating: state => state.updating,
    deleting: state => state.deleting,
    verified: state => state.verified
};

const actions = {
    async verify({ state }, integration) {
        state.verified = false;
        state.verifying = true;
        try {
            const response = await axios.post("Integrations/Klaviyo/verify", integration);
            state.verified = response;
        } finally {
            state.verifying = false;
        }
    },
    async create({ state }, integration) {
        state.adding = true;
        try {
            await axios.post("Integrations/Klaviyo", integration);
            state.newIntegrationId = "";
        } finally {
            state.adding = false;
        }
    },
    async update({ state }, integration) {
        state.updating = true;
        try {
            await axios.patch(`Integrations/Klaviyo/${integration.id}`, integration);
        } finally {
            state.updating = false;
        }
    },
    async delete({ state }, id) {
        state.deleting = true;
        try {
            await axios.delete(`integrations/Klaviyo/${id}`);
        } finally {
            state.deleting = false;
        }
    },
    async getTemplates(input, id) {
        var result = await axios.get(`integrations/Klaviyo/${id}/templates`);
        return result.data;
    },
};

const mutations = {
    clearVerfified(state) {
        state.newIntegrationId = "";
    }
};

export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true
};
