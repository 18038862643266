import axios from "axios";
const state = {
    loading: false
};

const getters = {
    loading: state => state.loading
};

const actions = {
    async deposit({ state }, { pricingPlanId, autoDeposit }) {
        state.loading = true;
        try {
            const response = await axios.post("credits", { pricingPlanId, autoDeposit });
            return response.data;
        } finally {
            state.loading = false;
        }
    }
};

const mutations = {};

export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true
};
