import router from "@/router";
import axios from "axios";
import auth from "@/auth";

import { i18n } from "@/plugins/vue-i18n";

const state = {
  loggingIn: false,
  registering: false,
  resetingPassword: false,
  settingNewPassword: false
};

const getters = {
  registering: state => state.registering,
  loggingIn: state => state.loggingIn,
  resetingPassword: state => state.resetingPassword,
  settingNewPassword: state => state.settingNewPassword
};

const actions = {
  loginFromToken(context, token){ 
      auth.clearToken(); 
      auth.setToken(token);
      router.push({ name: "Dashboard" }); 
  },
  async login({ state }, input) {
    state.loggingIn = true;
    try {
      const response = await axios.post("auth/login", input);
      const responseData = response.data;
      auth.setToken(responseData.data.token);
      router.push({ name: "Dashboard" });
    } finally {
      state.loggingIn = false;
    }
  },
  async delete({ state }) {
    state.loggingIn = true;
    try {
      await axios.delete("auth/delete");
      auth.clearToken();    
      window.location.reload();
    } finally {
      state.loggingIn = false;
    }
  },
  logOut() {
    auth.clearToken();    
    window.location.reload();
  },
  async register({ state }, input) {
    input.language = i18n.locale;
    state.registering = true;
    try {
      const response = await axios.post("auth/register", input);
      const responseData = response.data;
      auth.setToken(responseData.data.token);
      router.push({ name: "Dashboard" });
    } finally {
      state.registering = false;
    }
  },
  async confirmEmail(context, input) {
    await axios.post("auth/email-confirm", input);
    router.push({ name: "Dashboard" });
  },
  async sendPasswordResetLink({ state }, input) {
    state.resetingPassword = true;
    try {
      await axios.post("auth/password-forgot", input);
    } finally {
      state.resetingPassword = false;
    }
  },
  async setNewPassword({ state }, input) {
    state.settingNewPassword = true;
    try {
      await axios.post("auth/password-reset", input);
      const loginRequest = {
        email: input.email,
        password: input.newPassword
      };
      const response = await axios.post("auth/login", loginRequest);
      const responseData = response.data;
      auth.setToken(responseData.data.token);
    } finally {
      state.settingNewPassword = false;
    }

    state.settingNewPassword = false;
  }
};

const mutations = {};

export default {
  state,
  getters,
  actions,
  mutations
};
