import axios from "axios";

const state = {
    verifying: false,
    adding: false,
    updating: false,
    deleting: false,
    newIntegrationId: "",
    intergration:{},
};

const getters = {
    verifying: state => state.verifying,
    adding: state => state.adding,
    updating: state => state.updating,
    deleting: state => state.deleting,
    verified: state => state.newIntegrationId !== "" && state.newIntegrationId,
    integration: state => state.intergration,
};

const actions = {
    async verify({ state }, integration) {
        state.newIntegrationId = "";
        state.verifying = true;
        try {
            const response = await axios.post("integrations/dandomain", integration);
            state.newIntegrationId = response.data.integrationId;
        } finally {
            state.verifying = false;
        }
    },
    async fetchById({ state }, { id }) {
        state.fetching = true;
        try {
            const response = await axios.get(`integrations/DanDomain/${id}`);
            state.intergration = response.data;
        } finally {
            state.fetching = false;
        }
    },
    async add({ state }) {
        state.adding = true;
        try {
            await axios.post("integrations/dandomain/add", { integrationId: state.newIntegrationId });
            state.newIntegrationId = "";
        } finally {
            state.adding = false;
        }
    },
    async update({ state }, request) {
        state.updating = true;
        try {
            await axios.patch(`integrations/dandomain/${request.id}`, {
                domain: request.domain,
                apiKey: request.apiKey,
                shopIdentifier: request.shopId,
                webhookSecret: request.webhookSecret
            });
        } finally {
            state.updating = false;
        }
    },
    async delete({ state }, id) {
        state.deleting = true;
        try {
            await axios.delete(`integrations/dandomain/${id}`);
        } finally {
            state.deleting = false;
        }
    },
    async getSites(input, id) {
        try {
            const response = await axios.get(`integrations/dandomain/${id}/sites`);
            return response.data;
        }catch(error){
            console.debug(error);
            return [];
        }
    },
    async deleteMapping(input, { intergrationId, fromSiteId, toSiteId }) {
        const response = await axios.delete(`integrations/dandomain/${intergrationId}/VariantsMapping?fromSite=${fromSiteId}&toSite=${toSiteId}`);
        return response.data;
    },
    async getVariantGroups(input, intergrationId) {
        const response = await axios.get(`integrations/dandomain/${intergrationId}/VariantGroups`);
        return response.data;
    },
    async getVariants(input, { intergrationId, groupid }) {
        const response = await axios.get(`integrations/dandomain/${intergrationId}/VariantGroups/${groupid}/variants`);
        return response.data;
    },
    async GetVariantMapping({ state }, intergrationId) {
        state.adding = true;
        try {
            const response = await axios.get(`integrations/danDomain/${intergrationId}/VariantsMapping`);
            return response.data;
        } finally {
            state.adding = false;
        }
    },
    async createVariantMapping({ state }, { intergrationId, newMapping }) {
        state.adding = true;
        try {
            const response = await axios.post(`integrations/danDomain/${intergrationId}/CreateVariantsMapping`, newMapping);
            return response.data;
        } finally {
            state.adding = false;
        }
    },
    async updateVariantMapping({ state }, { intergrationId, newMapping }) {
        state.adding = true;
        try {
            const response = await axios.post(`integrations/danDomain/${intergrationId}/UpdateVariantsMapping`, newMapping);
            return response.data;
        } finally {
            state.adding = false;
        }
    },    
    async getAllIntergrationsSites() {
        try {
            const response = await axios.get(`integrations/dandomain/sites`);
            return response.data;
        }catch(error){
            return [];
        }
    
    },
    async getAllCmsPages(input, { integrationId, siteId }) {
        const response = await axios.get(
            `integrations/dandomain/${integrationId}/CmsPages/${siteId}`
        );
        return response.data;
    }
};

const mutations = {
    clearVerfified(state) {
        state.newIntegrationId = "";
    }
};

export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true
};
