import axios from "axios";
const state = {
  userInfo: {
    accountId: "",
    accountName: "",
    autoDeposit: false,
    credits: 0,
    email: "",
    emailConfirmed: false,
    paymentActivity: false,
    supportedLanguages: [],
    username: ""
  },
  initialized: false,
  loading: false
};

const getters = {
  userInfo: state => state.userInfo,
  loadingUserData: state => state.loading
};

const actions = {
  async fetchUserInfo({ state }) {
    if (state.initialized) return;

    state.loading = true;
    try {
      const response = await axios.get("userinfo");
      state.userInfo = response.data;
      state.initialized = true;
    } finally {
      state.loading = false;
    }
  },
  async reloadUserInfo({ state }) {
    state.loading = true;
    try {
      const response = await axios.get("userinfo");
      state.userInfo = response.data;
    } finally {
      state.loading = false;
    }
  }
};

const mutations = {
  setBalance(state, balance) {
    state.userInfo.credits = balance;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
