import VueJwtDecode from "vue-jwt-decode";

const tokenName = "auth_token";

export default {
  setToken(token) {
    localStorage.setItem(tokenName, token);
  },
  getToken() {
    const token = localStorage.getItem(tokenName);
    if (!token) return token;

    const jwt = VueJwtDecode.decode(token);
    const expiresAt = new Date(jwt.exp * 1000);
    const hasExpired = expiresAt < new Date();
    if (hasExpired) {
      console.log("has expired:", jwt)
      localStorage.removeItem(tokenName);
      return undefined;
    }
    return token;
  },
  isAuthorized() {
    return this.getToken() != undefined;
  },
  clearToken(){
    localStorage.removeItem(tokenName);
  }
};
