import axios from "axios";
const state = {
    invoices: null,
    loading: false
};

const getters = {
    invoices: state => state.invoices,
    loading: state => state.loading
};

const actions = {
    async fetch({ state }) {
        state.loading = true;
        try {
            const response = await axios.get("invoices");
            state.invoices = response.data;
        }
        finally {
            state.loading = false;
        }
    }
};

const mutations = {};

export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true
};
