import Vue from "vue";
import VueRouter from "vue-router";
import { initialize } from "@/plugins/vue-i18n";
import auth from "@/auth";

Vue.use(VueRouter);

const routes = [
  {
    path: "/register",
    name: "Register",
    component: () =>
      import(/* webpackChunkName: "auth" */ "../views/Register.vue"),
    meta: { bodyClasses: ["register", "footer"], anonymousOnly: true }
  },
  {
    path: "/AdminAccountLogin",
    name: "Admin Account Login",
    component: () =>
      import(/* webpackChunkName: "auth" */ "../views/Auth/AdminAccountLogin.vue"),
    meta: { bodyClasses: ["login", "footer"] }
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "auth" */ "../views/Login.vue"),
    meta: { bodyClasses: ["login", "footer"], anonymousOnly: true }
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: () =>
      import(/* webpackChunkName: "auth" */ "../views/ResetPassword.vue"),
    meta: { bodyClasses: ["login", "footer"], anonymousOnly: true }
  },
  {
    path: "/new-password",
    name: "NewPassword",
    component: () =>
      import(/* webpackChunkName: "auth" */ "../views/NewPassword.vue"),
    meta: { bodyClasses: ["login", "footer"], anonymousOnly: true }
  },
  {
    path: "/confirm-email",
    name: "ConfirmEmail",
    component: () =>
      import(/* webpackChunkName: "auth" */ "../views/ConfirmEmail.vue")
  },
  {
    path: "/dashboard",
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "../views/Dashboard.vue"),
    children: [
      {
        path: '',
        name: "Dashboard",
        component: () =>
          import(/* webpackChunkName: "dashboard" */ "../views/DashboardStart.vue"),
        meta: {
          bodyClasses: ["dashboard", "start"],
          icon: "https://templates.gotranslateit.com/images/icon-company-black.svg",
          title: "Dashboard.Route.Title",
          authorize: true
        },
      },
      {
        path: "/account",
        name: "Account",
        component: () =>
          import(/* webpackChunkName: "dashboard" */ "../views/Account.vue"),
        meta: {
          bodyClasses: ["dashboard"],
          icon: "https://templates.gotranslateit.com/images/icon-account-man.svg",
          title: "Account.Route.Title",
          authorize: true
        }
      },
      {
        path: "/integrations",
        name: "Integrations",
        component: () =>
          import(/* webpackChunkName: "dashboard" */ "../views/Integrations/Index.vue"),
        meta: {
          bodyClasses: ["dashboard"],
          icon: "https://templates.gotranslateit.com/images/icon-connectionsv1.svg",
          title: "Integrations.Route.Title",
          authorize: true
        }
      },
      {
        path: "/DanDomainVariantsMapping/:integrationId",
        name: "DanDomainVariantsMapping",
        component: () =>
          import(/* webpackChunkName: "dashboard" */ "../views/Integrations/DanDomain/VariantsMapping.vue"),
        meta: {
          bodyClasses: ["dashboard"],
          icon: "https://templates.gotranslateit.com/images/icon-connectionsv1.svg",
          title: "DanDomainVariantMap.Route.Title",
          authorize: true
        }
      },
      {
        path: "/tasks",
        component: () =>
          import(/* webpackChunkName: "dashboard" */ "../views/Tasks/Index.vue"),
        meta: {
          bodyClasses: ["dashboard"],
          icon: "https://templates.gotranslateit.com/images/icon-connectionsv1.svg",
          title: "Tasks.Route.Title",
          authorize: true
        },
        children: [
          {
            path: "",
            name: "Tasks",
            component: () =>
              import(/* webpackChunkName: "dashboard" */ "../views/Tasks/Overview.vue"),
            meta: {
              bodyClasses: ["dashboard", "tasks"],
              icon: "https://templates.gotranslateit.com/images/icon-connectionsv1.svg",
              title: "MyTasks.Route.Title",
              authorize: true
            }
          },
          {
            path: "NewTasks",
            name: "NewTasks",
            component: () =>
              import(/* webpackChunkName: "dashboard" */ "../views/Tasks/Overview.vue"),
            meta: {
              bodyClasses: ["dashboard", "tasks"],
              icon: "https://templates.gotranslateit.com/images/icon-connectionsv1.svg",
              title: "MyTasks.Route.Title",
              authorize: true
            }
          },
          {
            path: "ProcessingTasks",
            name: "ProcessingTasks",
            component: () =>
              import(/* webpackChunkName: "dashboard" */ "../views/Tasks/Overview.vue"),
            meta: {
              bodyClasses: ["dashboard", "tasks"],
              icon: "https://templates.gotranslateit.com/images/icon-connectionsv1.svg",
              title: "MyTasks.Route.Title",
              authorize: true
            }
          },
          {
            path: "CompletedTasks",
            name: "CompletedTasks",
            component: () =>
              import(/* webpackChunkName: "dashboard" */ "../views/Tasks/Overview.vue"),
            meta: {
              bodyClasses: ["dashboard", "tasks"],
              icon: "https://templates.gotranslateit.com/images/icon-connectionsv1.svg",
              title: "MyTasks.Route.Title",
              authorize: true
            }
          },
          {
            path: "FailedTasks",
            name: "FailedTasks",
            component: () =>
              import(/* webpackChunkName: "dashboard" */ "../views/Tasks/Overview.vue"),
            meta: {
              bodyClasses: ["dashboard", "tasks"],
              icon: "https://templates.gotranslateit.com/images/icon-connectionsv1.svg",
              title: "MyTasks.Route.Title",
              authorize: true
            }
          },
          {
            path: "create",
            name: "CreateTask",
            component: () =>
              import(/* webpackChunkName: "dashboard" */ "../views/Tasks/Create/Index.vue"),
            meta: {
              bodyClasses: ["dashboard", "tasks"],
              icon: "https://templates.gotranslateit.com/images/icon-connectionsv1.svg",
              title: "CreateTask.Route.Task",
              authorize: true
            }
          },
          {
            path: "failedTask/:taskId",
            name: "FailedTask",
            component: () =>
              import(/* webpackChunkName: "dashboard" */ "../views/Tasks/Failed/FailedTask.vue"),
            meta: {
              bodyClasses: ["dashboard", "tasks"],
              icon: "https://templates.gotranslateit.com/images/icon-connectionsv1.svg",
              title: "FailedTask.Route.Title",
              authorize: true
            }
          },
          {
            path: "taskinfo/:taskId",
            name: "TaskInfo",
            component: () =>
              import(/* webpackChunkName: "dashboard" */ "../views/Tasks/Info/Index.vue"),
            meta: {
              bodyClasses: ["dashboard", "tasks"],
              icon: "https://templates.gotranslateit.com/images/icon-connectionsv1.svg",
              title: "TaskInfo.Route.Title",
              authorize: true
            }
          },
          {
            path: "/presets",
            name: "Presets",
            component: () =>
              import(/* webpackChunkName: "dashboard" */ "../views/Tasks/Presets/Index.vue"),
            meta: {
              bodyClasses: ["dashboard", "presets"],
              icon: "https://templates.gotranslateit.com/images/icon-connectionsv1.svg",
              title: "Presets.Route.Title",
              authorize: true
            }
          },
          {
            path: "/findAndReplace",
            name: "FindAndReplace",
            component: () =>
              import(/* webpackChunkName: "dashboard" */ "../views/Tasks/FindAndReplace/Create/Index.vue"),
            meta: {
              bodyClasses: ["dashboard", "findAndReplace", "tasks"],
              icon: "https://templates.gotranslateit.com/images/icon-connectionsv1.svg",
              title: "Presets.Route.Title",
              authorize: true
            }
          },
          {
            path: "/automations",
            name: "Automations",
            component: () =>
              import(/* webpackChunkName: "dashboard" */ "../views/Automations/Index.vue"),
            meta: {
              bodyClasses: ["dashboard", "automations"],
              icon: "https://templates.gotranslateit.com/images/icon-connectionsv1.svg",
              title: "Automations.Route.Title",
              authorize: true
            }
          },
          {
            path: "/createAutomation",
            component: () =>
              import(/* webpackChunkName: "dashboard" */ "../views/Automations/Create/Index.vue"),
            meta: {
              bodyClasses: ["dashboard", "automations"],
              icon: "https://templates.gotranslateit.com/images/icon-connectionsv1.svg",
              title: "Root.CreateAutomation.Route.Title",
              authorize: true
            },
            children: [
              {
                path: "",
                name: "CreateAutomation",
                component: () =>
                  import(/* webpackChunkName: "dashboard" */ "../views/Automations/Create/PickIntegration.vue"),
                meta: {
                  bodyClasses: ["dashboard", "automations"],
                  icon: "https://templates.gotranslateit.com/images/icon-connectionsv1.svg",
                  title: "CreateAutomation.Route.Title",
                  authorize: true
                }
              },
              {
                path: "/Automation/:id",
                name: "EditAutomation",
                component: () =>
                  import(/* webpackChunkName: "dashboard" */ "../views/Automations/Create/DanDomain/Product.vue"),
                meta: {
                  bodyClasses: ["dashboard", "automations"],
                  icon: "https://templates.gotranslateit.com/images/icon-connectionsv1.svg",
                  title: "EditAutomation.Route.Title",
                  authorize: true
                }
              },
              {
                path: "/DDHostedShop/Automation/:id",
                name: "EditDDHostedShopAutomation",
                component: () =>
                  import(/* webpackChunkName: "dashboard" */ "../views/Automations/Create/DDHostedShop/Product.vue"),
                meta: {
                  bodyClasses: ["dashboard", "automations"],
                  icon: "https://templates.gotranslateit.com/images/icon-connectionsv1.svg",
                  title: "EditAutomation.Route.Title",
                  authorize: true
                }
              },
              {
                path: "danDomain",
                name: "CreateDanDomainAutomation",
                component: () =>
                  import(/* webpackChunkName: "dashboard" */ "../views/Automations/Create/DanDomain/Product.vue"),
                meta: {
                  bodyClasses: ["dashboard", "automations"],
                  icon: "https://templates.gotranslateit.com/images/icon-connectionsv1.svg",
                  title: "CreateDanDomainAutomation.Route.Title",
                  authorize: true
                }
              },
              {
                path: "ddHostedShop",
                name: "CreateDDHostedShopAutomation",
                component: () =>
                  import(/* webpackChunkName: "dashboard" */ "../views/Automations/Create/DDHostedShop/Product.vue"),
                meta: {
                  bodyClasses: ["dashboard", "automations"],
                  icon: "https://templates.gotranslateit.com/images/icon-connectionsv1.svg",
                  title: "DDHostedShopAutomation.Route.Title",
                  authorize: true
                }
              }
            ]
          }
        ]
      },

      
      {
        path: "/integrations/create/dandomain",
        name: "CreateDanDomain",
        component: () =>
          import(/* webpackChunkName: "dashboard" */ "../views/Integrations/CreateDanDomain.vue"),
        meta: {
          bodyClasses: ["dashboard", "integration"],
          title: "CreateDanDomain.Route.Title",
          icon: "https://templates.gotranslateit.com/images/icon-connectionsv1.svg",
          authorize: true
        }
      },{
        path: "/integrations/create/DDHostedShop",
        name: "CreateDDHostedShop",
        component: () =>
          import(/* webpackChunkName: "dashboard" */ "../views/Integrations/DDHostedShop/CreateDDHostedShop.vue"),
        meta: {
          bodyClasses: ["dashboard", "integration"],
          title: "CreateDDHostedShop.Route.Title",
          icon: "https://templates.gotranslateit.com/images/icon-connectionsv1.svg",
          authorize: true
        }
      },{
        path: "/integrations/create/CreateKlaviyo",
        name: "CreateKlaviyo",
        component: () =>
          import("../views/Integrations/Klaviyo/CreateKlaviyo.vue"),
        meta: {
          bodyClasses: ["dashboard", "integration"],
          title: "CreateKlaviyo.Route.Title",
          icon: "https://templates.gotranslateit.com/images/icon-connectionsv1.svg",
          authorize: true
        }
      },
      {
        path: "/exclusions",
        name: "Exclusions",
        component: () =>
          import(/* webpackChunkName: "dashboard" */ "../views/Exclusions.vue"),
        meta: {
          bodyClasses: ["dashboard", "exclusions"],
          title: "Exclusions.Route.Title",
          icon: "https://templates.gotranslateit.com/images/icon-exclusion.svg",
          authorize: true
        }
      },
      {
        path: "/replacements",
        name: "Replacements",
        component: () =>
          import(/* webpackChunkName: "dashboard" */ "../views/Replacements.vue"),
        meta: {
          bodyClasses: ["dashboard", "replacements"],
          title: "Replacements.Route.Title",
          icon: "https://templates.gotranslateit.com/images/icon-replacement.svg",
          authorize: true
        }
      },
      {
        path: "/buy",
        name: "Purchase",
        component: () =>
          import(/* webpackChunkName: "dashboard" */ "../views/Purchase.vue"),
        meta: {
          bodyClasses: ["dashboard", "buy"],
          title: "Purchase.Route.Title",
          icon: "https://templates.gotranslateit.com/images/icon-payment.svg",
          authorize: true
        }
      },
      {
        path: "/invoices",
        name: "Invoices",
        component: () =>
          import(/* webpackChunkName: "dashboard" */ "../views/Invoices.vue"),
        meta: {
          bodyClasses: ["dashboard", "invoices"],
          title: "Invoices.Route.Title",
          icon: "https://templates.gotranslateit.com/images/icon-invoice.svg",
          authorize: true
        }
      }
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(async (to, from, next) => {
  if (to.path === "/") {
    if (!auth.isAuthorized()) {
      next({
        name: "Login"
      });
    } else {
      next({
        name: "Dashboard"
      });
    }
    return;
  }

  if (to.meta.anonymousOnly && auth.isAuthorized()) {
    next({
      name: "Dashboard"
    });
    return;
  }

  if (to.meta.authorize && !auth.isAuthorized()) {
    next({
      name: "Login"
    });
    return;
  }

  if (to.meta.bodyClasses) {
    document.getElementsByTagName("body")[0].className = "";
    for (let i = 0; i < to.meta.bodyClasses.length; i++) {
      const c = to.meta.bodyClasses[i];
      document.getElementsByTagName("body")[0].classList.add(c);
    }
  }
  await initialize();
  next();
});

export default router;
