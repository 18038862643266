import axios from "axios";
const state = {
    loading: false,
    loadingStats:false,
    accountStats:null
};

const getters = {
    loading: state => state.loading,
    loadingStats: state => state.loadingStats,
    getaccountStats: state => state.accountStats,
};

const actions = {
    async submit({ state }, { accountDetails }) {
        state.loading = true;
        try {
            await axios.post("accountDetails", accountDetails);
        } finally {
            state.loading = false;
        }
    },
    async getStats({ state }) {
        state.loadingStats = true;
        try {
            var response = await axios.get("AccountDetails/accountStats");
            state.accountStats = response.data;
        } finally {
            state.loadingStats = false;
        }
    }
};

const mutations = {};

export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true
};
