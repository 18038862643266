import axios from "axios";
const state = {
  billingAddress: null,
  loading: false
};

const getters = {
  billingAddress: state => state.billingAddress,
  loading: state => state.loading
};

const actions = {
  async fetch({ state }) {
    state.loading = true;
    try {
      const response = await axios.get("billingInformation");
      state.billingAddress = response.data;
    } 
    catch(e){
      if (e.status === 404) state.billingAddress = null;
      else throw e;
    }
    finally {
      state.loading = false;
    }
  },
  async submit({ state }, { billingAddress }) {
    state.loading = true;
    try {
      await axios.post("billingInformation", billingAddress);
    } finally {
      state.loading = false;
    }
  }
};

const mutations = {};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
};
