import axios from "axios";
const state = {
  tasks: [],
  taskView: "ProcessingTasks",
  pageView: 1,
  paginationTasks: [],
  tasksCount: {
    NewTasks: 0,
    ProcessingTasks: 0,
    CompletedTasks: 0,
    FailedTasks: 0
  },
  task: {},
  chunkInfo: {},
  loading: false,
  executing: false
};

const getters = {
  tasks: state => state.tasks,
  pageView: state => state.pageView,
  taskView: state => state.taskView,
  tasksCount: state => state.tasksCount,
  paginationTasks: state => state.paginationTasks,
  task: state => state.task,
  loading: state => state.loading,
  executing: state => state.executing,
};

const actions = {
  async fetch({ state }) {
    state.loading = true;
    try {
      const response = await axios.get("tasks");
      state.tasks = response.data;
      state.tasks.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate));
    } finally {
      state.loading = false;
    }
  },
  async fetchPagination({ state }, { view, pageNo, set = false }) {
    state.loading = true;
    try {
      const response = await axios.get(`tasks/${view}/${pageNo}`);

      if (state.tasksCount[view] != response.data.totalTasks)
        state.tasksCount[view] = response.data.totalTasks;

      response.data.tasks.forEach(task => {
        let index = state.tasks.findIndex(t => t.id == task.id);
        if (index == -1) state.tasks.push(task);
        else {
          state.tasks.splice(index, 1);
          state.tasks.splice(index, 0, task);
        }

      });

      if (set) {
        state.paginationTasks = response.data.tasks;
        state.paginationTasks.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate));
      }

      state.tasks.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate));
    } finally {
      state.loading = false;
    }
  },
  async fetchChunks({ state }, { taskId, page, pageSize, }) {
    state.loading = true;
    try {
      const response = await axios.get(`tasks/${taskId}/Chunks?page=${page}&pageSize=${pageSize}`);
      state.task = response.data;
    } finally {
      state.loading = false;
    }
  },
  async fetchChunkInfo(context, { taskId, chunkId }) {
    const response = await axios.get(`tasks/${taskId}/Chunks/${encodeURIComponent(chunkId)}/info`);
    return response.data;
  },
  async pollFetch({ state }, { id }) { // refetches until status of task with ID has changed in response
    state.loading = true;
    const task = state.tasks.find(t => t.id === id);
    const currentStatus = task.status;
    const currentJobStatus = task.jobDetails.status;

    try {
      let count = 0;
      let tryAgain = true;

      while (tryAgain && count <= 10) {
        count++;

        const response = await axios.get(`tasks/${id}`);
        const fetchedTask = response.data;
        if (
          fetchedTask.status === currentStatus &&
          fetchedTask.jobDetails.status === currentJobStatus
        )
          continue;

        task.status = fetchedTask.status;
        task.jobDetails.status = fetchedTask.jobDetails.status;

        tryAgain = false;
      }
    } finally {
      state.loading = false;
    }
  },
  async add({ state }, { task }) {
    state.loading = true;
    try {
      await axios.post("tasks/danDomain/siteToSite", task);
    } finally {
      state.loading = false;
    }
  },
  async addDanDomainFindAndReplace({ state }, { task }) {
    state.loading = true;
    try {
      await axios.post("tasks/danDomain/findAndReplace", task);
    } finally {
      state.loading = false;
    }
  },
  async addDDHostedShopFindAndReplace({ state }, { task }) {
    state.loading = true;
    try {
      await axios.post("tasks/DDHosted/findAndReplace", task);
    } finally {
      state.loading = false;
    }
  },
  async addDDHostedShop({ state }, { task }) {
    state.loading = true;
    try {
      await axios.post("tasks/DDHostedShop/siteToSite", task);
    } finally {
      state.loading = false;
    }
  },
  async addKlaviyoTask({ state }, { task }) {
    state.loading = true;
    try {
      await axios.post("tasks/Klaviyo/CreateTask", task);
    } finally {
      state.loading = false;
    }
  },
  async execute({ state }, { taskId }) {
    state.loading = true;
    state.executing = true;
    try {
      await axios.post(`tasks/${taskId}/execute`);
    } finally {
      state.loading = false;
      state.executing = false;
    }
  },
  async executeAll({ state }) {
    state.loading = true;
    state.executing = true;
    try {
      await axios.post(`tasks/execute/allTasks`);
    } finally {
      state.loading = false;
      state.executing = false;
    }
  },
  async cancelAll({ state }) {
    state.loading = true;
    state.executing = true;
    try {
      await axios.post(`tasks/cancel/allTasks`);
    } finally {
      state.loading = false;
      state.executing = false;
    }
  },
  async retry({ state }, { taskId }) {
    state.loading = true;
    state.executing = true;
    try {
      await axios.post(`tasks/${taskId}/retry`);
    } finally {
      state.loading = false;
      state.executing = false;
    }
  },
  async cancel({ state }, { taskId }) {
    state.loading = true;
    try {
      await axios.post(`tasks/${taskId}/cancel`);
    } finally {
      state.loading = false;
    }
  },
  setTaskView({ state }, { view }) {
    state.taskView = view;
  },
  setpageView({ state }, { pageView }) {
    state.pageView = pageView;
  },
};

const mutations = {
  async setTaskStatus(state, { taskId }) {

    let index = state.paginationTasks.findIndex(t => t.id == taskId);

    if (index > -1) {
      const response = await axios.get(`tasks/${state.taskView}/${state.pageView}`);
      state.paginationTasks = response.data.tasks;
      state.paginationTasks.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate));
    }

    const cResponse = await axios.get(`tasks/count`);
    state.tasksCount.NewTasks = cResponse.data.newTasksCount;
    state.tasksCount.ProcessingTasks = cResponse.data.processingTasksCount;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
};
