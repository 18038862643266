import Vue from "vue";
import VueSimpleAlert from "vue-simple-alert";

Vue.mixin({
    methods: {
        $confirm: function (value, options) {
            let text = value;
            if(options != undefined && options != null){
                options.html = value;
                text = null;
            }else{
                options = null;
            }

            return VueSimpleAlert.confirm(text, null, null, options);
        },
    },
})