import Vue from "vue";
import Vuex from "vuex";

import accountDetails from "./modules/account-details";
import auth from "./modules/auth";
import automations from "./modules/automations";
import billingAddress from "./modules/billing-address";
import credits from "./modules/credits";
import exclusions from "./modules/exclusions";
import invoices from "./modules/invoices";
import paymentMethod from "./modules/payment-method";
import presets from "./modules/presets";
import pricingPlans from "./modules/pricing-plans";
import replacements from "./modules/replacements";
import findandreplacements from "./modules/findandreplacements";
import tasks from "./modules/tasks";
import userInfo from "./modules/user-info";
import quote from "./modules/quote";

import integrations from "./modules/integrations";
import danDomainIntegrations from "./modules/integrations/dan-domain-integrations";
import ddHostedShopIntergrations from "./modules/integrations/dd-hosted-shop-integrations";
import klaviyoIntegration from "./modules/integrations/klaviyo-integration";



Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    accountDetails,
    auth,
    automations,
    billingAddress,
    credits,
    exclusions,
    invoices,
    paymentMethod,
    presets,
    pricingPlans,
    replacements,
    tasks,
    userInfo,
    findandreplacements,
    integrations,
    danDomainIntegrations,
    ddHostedShopIntergrations,
    klaviyoIntegration,
    quote
  }
});
