import axios from "axios";
const state = {
    loading: false,
    quotes: []
};

const getters = {
    loadingQuotes: state => state.loading,
    getQuotes: state => state.quotes,
};

const actions = {
    async fetchQuotes({ state }) {
        if (state.quotes.length) return;

        state.loading = true;
        try {
            const response = await axios.get("Quotes");
            console.log("response " + response.data);
            if (response.data != undefined && response.data != "") {
                state.quotes = response.data.rss.channel.item;
            }
        } finally {
            state.loading = false;
        }
    },
};

const mutations = {};

export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true
};
