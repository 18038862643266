import axios from "axios";
const state = {
  presets: [],
  loading: false
};

const getters = {
  presets: state => state.presets,
  loading: state => state.loading
};

const actions = {
  async fetch({ state }) {
    state.loading = true;
    try {
      const response = await axios.get("presets");
      state.presets = response.data;
    } finally {
      state.loading = false;
    }
  },
  async add({ state }, { preset }) {
    state.loading = true;
    try {
      await axios.post("presets", preset);
    } finally {
      state.loading = false;
    }
  },
  async update({ state }, { presetId, preset }) {
    state.loading = true;
    try {
      await axios.patch(`presets/${presetId}`, preset);
    } finally {
      state.loading = false;
    }
  },
  async delete({ state }, { presetId }) {
    state.loading = true;
    try {
      await axios.delete(`presets/${presetId}`);
    } finally {
      state.loading = false;
    }
  }
};

const mutations = {};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
};
