import "@babel/polyfill";
import "mutationobserver-shim";

import "./filters/currency";
import "./filters/number";

import "./plugins/axios";
import "./plugins/confirm.js";
import "./plugins/country-select";
import "./plugins/html-escape";
import "./plugins/loader";
import "./plugins/modal";
import "./plugins/moment";
import "./plugins/notification";
import "./plugins/signalr";
import "./plugins/skeleton.js";
import "./plugins/tooltip.js";
import "./plugins/vee-validate";
import "./plugins/overlay";
import "./plugins/confetti";

import { i18n } from "./plugins/vue-i18n";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App),
  i18n
}).$mount("#app");
