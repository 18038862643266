import axios from "axios";
const state = {
    automations: [],
    loading: false
};

const getters = {
    automations: state => state.automations,
    loading: state => state.loading
};

const actions = {
    async fetch({ state }) {
        state.loading = true;
        try {
            const response = await axios.get("automations");
            state.automations = response.data;
        } finally {
            state.loading = false;
        }
    },
    async fetchDanDomainProductAutomation({ state }, { id }) {
        state.loading = true;
        try {
            const response = await axios.get(`automations/danDomain/product/${id}`);
            return response.data;
        } finally {
            state.loading = false;
        }
    },
    async updateDanDomainProductAutomation({ state }, { id, automation }) {
        state.loading = true;
        try {
            await axios.patch(`automations/danDomain/product/${id}`, automation);
        } finally {
            state.loading = false;
        }
    },
    async add({ state }, { automation }) {
        state.loading = true;
        try {
            await axios.post("automations/danDomain/product", automation);
        } finally {
            state.loading = false;
        }
    },
    async addDDHostedShop({ state }, { automation }) {
        state.loading = true;
        try {
            await axios.post("automations/ddHostedShop/product", automation);
        } finally {
            state.loading = false;
        }
    },
    async updateDDHostedShopProductAutomation({ state }, { id, automation }) {
        state.loading = true;
        try {
            await axios.patch(`automations/ddHostedShop/product/${id}`, automation);
        } finally {
            state.loading = false;
        }
    },
    async fetchDDHostedProductAutomation({ state }, { id }) {
        state.loading = true;
        try {
            const response = await axios.get(`automations/ddHostedShop/product/${id}`);
            return response.data;
        } finally {
            state.loading = false;
        }
    },
    async delete({ state }, automation) {
        state.loading = true;

        const index = state.automations.indexOf(automation);
        state.automations.splice(index, 1);

        try {
            await axios.delete(`automations/${automation.id}`);
        } catch (e) {
            state.automations.splice(index, 0, automation);
        } finally {
            state.loading = false;
        }
    },
    async setDisabled({ state }, { id, disabled }) {
        state.loading = true;

        try {
            await axios.patch(`automations/${id}/Disabled`, { disable: disabled });
        } finally {
            state.loading = false;
        }
    }
};

const mutations = {};

export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true
};
