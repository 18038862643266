import axios from "axios";
const state = {
  pricingPlans: [],
  loading: false
};

const getters = {
    pricingPlans: state => state.pricingPlans,
  loading: state => state.loading
};

const actions = {
  async fetch({ state }) {
    state.loading = true;
    try {
      const response = await axios.get("pricingPlans");
      state.pricingPlans = response.data;
      state.pricingPlans.sort((a, b) => (a.refillPriceAmount > b.refillPriceAmount) ? 1 : -1)
    } finally {
      state.loading = false;
    }
  }
};

const mutations = {};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
};
