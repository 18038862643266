import axios from "axios";
const state = {
    currentPaymentMethod: null,
    loading: false
};

const getters = {
    currentPaymentMethod: state => state.currentPaymentMethod,
    loading: state => state.loading
};

const actions = {
    async fetch({ state }) {
        state.loading = true;
        try {
            const response = await axios.get("paymentmethods");
            state.currentPaymentMethod = response.data;
        } catch (e) {
            if (e.status === 404) state.currentPaymentMethod = null;
            else throw e;
        } finally {
            state.loading = false;
        }
    },
    async submit({ state }, { paymentMethodId }) {
        state.loading = true;
        try {
            const response = await axios.post("paymentmethods", { paymentMethodId: paymentMethodId });
            return response.data;
        } finally {
            state.loading = false;
        }
    },
    async clear({ state }) {
        state.loading = true;
        try {
            await axios.delete("paymentmethods");
        } finally {
            state.loading = false;
        }
    }
};

const mutations = {};

export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true
};
