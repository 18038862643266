import Vue from 'vue'
import * as SignalR from "@microsoft/signalr/dist/browser/signalr";
import auth from "@/auth";
import axios from 'axios';
import jwtDecode from "jwt-decode";

const EventEmitter = require("events");

const defaultOptions = {
    log: false
};

class SocketConnection extends EventEmitter {
    constructor(connection) {
        super();

        this.connection = connection;
        this.listened = [];
        this.socket = false;

        this.toSend = [];

        this.offline = false;
    }

    async _initialize(
        connection = "",
        transportType = SignalR.HttpTransportType.None
    ) {
        const con = connection || this.connection;
        
        let decodedJwt = jwtDecode(auth.getToken());

        const config = {
            headers: { 'x-ms-signalr-userid': decodedJwt.account_id}
        };
        axios.post(con, null, config)
            .then(response => {
                if(!response.data) return;
                
                const options = {
                    accessTokenFactory: () => response.data.AccessToken
                }
                const socket = new SignalR.HubConnectionBuilder()
                    .withUrl(response.data.Url, options)
                    // .configureLogging(SignalR.LogLevel.Trace)
                    .build(transportType);

                socket.connection.onclose = async () => {
                    if (this.options.log) console.log("Reconnecting...");

                    this.socket = false;
                    /* eslint-disable no-underscore-dangle */
                    await this._initialize(con, SignalR.HttpTransportType.LongPolling);
                    this.emit("reconnect");
                };

                socket.start({ withCredentials: false })
                    .then(() => { console.log("Connection Started"); console.log(socket.connectionId) })
                    .catch(err => console.log("Unable to start Connection: " + err));

                this.socket = socket;
                this.emit("init");
            })
    }
    
    async start(options = {}) {
        this.options = Object.assign(defaultOptions, options);

        await this._initialize();
    }

    listen(method) {
        if (this.offline) return;

        if (this.listened.some(v => v === method)) return;
        this.listened.push(method);

        this.on("init", () => {
            this.socket.on(method, data => {
                if (this.options.log) console.log({ type: "receive", method, data });

                this.emit(method, data);
            });
        });
    }

    send(methodName, ...args) {
        if (this.options.log) console.log({ type: "send", methodName, args });
        if (this.offline) return;

        if (this.socket) {
            this.socket.send(methodName, ...args);
            return;
        }

        this.once("init", () => this.socket.send(methodName, ...args));
    }

    async invoke(methodName, ...args) {
        if (this.options.log) console.log({ type: "invoke", methodName, args });
        if (this.offline) return false;

        if (this.socket) {
            return this.socket.invoke(methodName, ...args);
        }

        return new Promise(resolve =>
            this.once("init", () => resolve(this.socket.invoke(methodName, ...args)))
        );
    }
}

if (!SignalR) {
    throw new Error("[Vue-SignalR] Cannot locate signalr-client");
}

function install(Vue, connection) {
    if (!connection) {
        throw new Error("[Vue-SignalR] Cannot locate connection");
    }

    const Socket = new SocketConnection(connection);

    Vue.socket = Socket;

    Object.defineProperties(Vue.prototype, {
        $socket: {
            get() {
                return Socket;
            }
        }
    });

    Vue.mixin({
        created() {
            if (this.$options.sockets) {
                const methods = Object.getOwnPropertyNames(this.$options.sockets);

                methods.forEach(method => {
                    Socket.listen(method);

                    Socket.on(method, data =>
                        this.$options.sockets[method].call(this, data)
                    );
                });
            }

            if (this.$options.subscribe) {
                Socket.on("authenticated", () => {
                    this.$options.subscribe.forEach(channel => {
                        Socket.invoke("join", channel);
                    });
                });
            }
        },
        beforeDestroy() {
            // Make sure to cleanup SignalR event handlers when removing the component
            if (this.$options.sockets) {
                Socket.socket.connection.onclose = function () { };
                Socket.socket.stop();
                Socket.socket.completeClose();
            }
        }
    });
}

Vue.use(install, process.env.VUE_APP_SIGNALR_NEGOTIATEURL);
