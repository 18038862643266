import Vue from "vue";

Vue.mixin({
    methods: {
        $escapeHtml: function (value) {
            return value.replace(/&/g, "&amp;")
                .replace(/</g, "&lt;")
                .replace(/>/g, "&gt;")
                .replace(/"/g, "&quot;")
                .replace(/'/g, "&#039;");
        },
    },
})