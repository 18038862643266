import Vue from "vue";
import {
  ValidationProvider,
  ValidationObserver,
  extend,
  configure
} from "vee-validate";
import { email, required, numeric } from "vee-validate/dist/rules";

// No message specified.
extend("email", { ...email, message: "Please enter a valid email address" });

extend("password", {
  validate: value => {
    return value.length >= 8;
  },
  message: "Your password needs to be at least 8 characters"
});

configure({
  classes: {
    valid: "is-valid",
    invalid: "is-invalid"
  }
});

extend("phonenumber", {
  ...numeric,
  message: "Please enter a valid phone number"
});

extend("required", {
  ...required,
  message: "This field is required"
});

extend("domain", {
  validate(value) {
    if (/^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/.test(value))
      return true;
    try {
      new URL(value);
      return true;
    } catch { return false; }
  },
  message: "Please enter a valid domain"
})

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
